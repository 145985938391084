import React from "react";
// reactstrap components
// core components
import MultiDropdownNavbar from "./../../components/Navbars/MultiDropdownNavbar.js";
import LandingPageHeader from "./../../components/Headers/LandingPageHeader.js";

function LandingPage() {
	document.documentElement.classList.remove("nav-open");
	React.useEffect(() => {
		document.body.classList.add("landing-page");
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
		return function cleanup() {
			document.body.classList.remove("landing-page");
		};
	});
	return (
		<>
			<MultiDropdownNavbar />
			<LandingPageHeader />
		</>
	);
}

export default LandingPage;
