import ContactPage from "../views/ContactPage";
import LandingPage from "../views/examples/LandingPage";
import React from "react";

const routes = [
	//all other entries above this
	ContactPage,
	{
		path: "/",
		element: <LandingPage />,
		children: [],
	},
];

export default routes;
