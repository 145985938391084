import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import { Container, Navbar, NavbarBrand } from "reactstrap";

function MultiDropdownNavbar() {
	return (
		<>
			<Navbar
				className={classnames("fixed-top", "navbar-transparent")}
				id="navbar-main"
				expand="lg"
			>
				<Container>
					<div className="navbar-translate">
						<NavbarBrand id="navbar-brand" to="/" tag={Link}>
							Virtualix LTD
						</NavbarBrand>
					</div>
				</Container>
			</Navbar>
		</>
	);
}

export default MultiDropdownNavbar;
