import React from "react";
// reactstrap components
import { Button, Container } from "reactstrap";

// core components

function LandingPageHeader() {
	let pageHeader = React.createRef();

	React.useEffect(() => {
		if (window.innerWidth > 991) {
			const updateScroll = () => {
				let windowScrollTop = window.pageYOffset / 3;
				pageHeader.current.style.transform =
					"translate3d(0," + windowScrollTop + "px,0)";
			};
			window.addEventListener("scroll", updateScroll);
			return function cleanup() {
				window.removeEventListener("scroll", updateScroll);
			};
		}
	});

	return (
		<>
			<div
				className="page-header"
				ref={pageHeader}
				style={{
					backgroundImage:
						"url(" + require("assets/img/sections/david-marcu.jpg") + ")",
				}}
			>
				<div className="filter" />
				<div className="content-center">
					<Container>
						<div className="motto">
							<h1 className="title">We make websites that make you money</h1>
							<h3 className="description">
								Our own website, however, is under construction. See you soon!
							</h3>
							<br />
							<Button
								className="btn-round"
								color="neutral"
								type="button"
								outline
							>
								Contact
							</Button>
						</div>
					</Container>
				</div>
			</div>
		</>
	);
}

export default LandingPageHeader;
